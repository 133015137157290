<template>
    <LayoutNext>
        <template #page-title>
            Pex Allowlist
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-flex py-3 px-0">
                <div class="d-flex justify-content-start">
                    <MDBBtn class="mb-md-0 mb-3 fw-bold px-3 py-2" size="sm" color="primary"
                        @click="createWhitelistModal = true">
                        <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                        New Whitelist
                    </MDBBtn>
                </div>
                <MDBModal id="createWhitelistModal" tabindex="-1" labelledby="CreateWhitelistLabel"
                    v-model="createWhitelistModal" size="lg" :static-backdrop="true">
                    <MDBModalHeader class="py-3 px-4">
                        <MDBModalTitle class="fw-bold" id="CreateWhitelistLabel">
                            Create Whitelist
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody class="p-4">
                        <h6 class="fw-bold">Name</h6>
                        <MDBInput class="mb-2" placeholder="Enter Whitelist Name *" type="text" v-model="whitelistName"
                            required invalidFeedback="Please Enter Whitelist Name" />
                        <h6 class="mt-4 fw-bold">Add Users/Channel</h6>
                        <p>
                            Enter URLs of the Users/Channel you would like to add to your
                            whitelist.
                            <br />
                            Multiple URLs must be entered on a separate line or separated by a
                            comma (max 20,000).
                            <br />
                            Valid URLs must start with either
                            <strong>http:// or https://</strong>
                            <br />
                            <u>If adding a large amount of Users/Channels, please allow up to 5
                                minutes to complete.</u>
                        </p>
                        <MDBTextarea class="w-100" rows="4" type="textarea" placeholder="Enter Users/Channel"
                            v-model.trim="usersChannel" required />
                        <h6 class="mt-4 mb-2 fw-bold">Date Range</h6>
                        <div class="d-flex gap-3 mb-1">
                            <MDBDatepicker v-model="startDate" label="Select Start date" required />
                            <MDBDatepicker v-model="endDate" label="Select End date" required />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter class="d-flex justify-content-end py-3 gap-2">
                        <MDBBtn class="fw-bold" type="button" size="sm" @click="createWhitelistModal = false">Close
                        </MDBBtn>
                        <MDBBtn v-if="isLoading" class="fw-bold" type="button" size="sm" color="primary" disabled>
                            Creating...</MDBBtn>
                        <MDBBtn v-else class="fw-bold" type="button" size="sm" color="primary" @click="createWhitelist"
                            :disabled="!isFormComplete">
                            Create Whitelist</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container"  ref="el">
                    <MDBTable hover class="align-middle mb-0">
                        <thead class="table-light">
                            <tr>
                                <th class="sticky-top" scope="col">Name</th>
                                <th class="sticky-top" scope="col">Date Range</th>
                                <th class="sticky-top" scope="col">Users/Channels</th>
                                <th class="sticky-top" scope="col">Created</th>
                                <th class="sticky-top" scope="col">Updated</th>
                                <th class="sticky-top" scope="col">Status</th>
                                <!--<th scope="col">Action</th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="isLoading">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr class="row-action" v-for="(whitelist, index) in whitelistList" :key="index"
                                    @click="viewWhitelist(whitelist.whitelistIdString)">
                                    <td>{{ whitelist.title }}</td>
                                    <td>{{ dateLimitString(whitelist.datesLimit) }}</td>
                                    <td>1</td>
                                    <td>{{ parseDateLong(whitelist.createdAt) }}</td>
                                    <td>{{ parseDateLong(whitelist.updatedAt) }}</td>
                                    <td>
                                        <template v-if="whitelist.status != ''">
                                            <MDBBadge :color="statusBadge(whitelist.status)">
                                                {{ whitelist.status }}
                                            </MDBBadge>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
            </MDBCardBody>
            <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
                color="success" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Success </template>
                Whitelist Created Successfully
            </MDBToast>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardHeader, MDBCardBody, MDBInput, MDBBtn, MDBTable, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBTextarea, MDBDatepicker, MDBToast, MDBBadge, MDBIcon } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { ref, onMounted, computed, watch } from "vue";
import { GetWhitelist } from "@/services/Whitelist/GetWhitelist";
import { PostWhitelist } from "@/services/Whitelist/PostWhitelist";
import { parseDateLong, parseDateForBackend } from "@/helpers/parseDate";
import { useInfiniteScroll } from "@vueuse/core";
import { useTitle } from "@vueuse/core";

useTitle("Pex Allowlist | CreatorShield");

const selectedSortBy = ref();
watch(selectedSortBy, async (newVal) => {
    whitelistList.value = [];
    if (newVal === 0) {
        whitelistList.value = await getWhitelist();
    } else {
        whitelistList.value = await getWhitelist({ status: newVal });
    }
    isLoading.value = false;
});

const whitelistList = ref([]);
const viewWhitelist = (id) => {
    console.log(id);
};

const createWhitelist = async () => {
    isLoading.value = true;
    const formBody = {
        title: whitelistName.value,
        datesLimit: {
            startDate: parseDateForBackend(startDate.value),
            endDate: parseDateForBackend(endDate.value),
        },
        sourceUrl: usersChannel.value.replace(/\n/g, ","),
    };
    await PostWhitelist(formBody);
    createWhitelistModal.value = false;
    toast.value = true;
    whitelistList.value = await getWhitelist();
    isLoading.value = false;
};

const isLoading = ref(true);
const getWhitelist = async (params) => {
    isLoading.value = true;
    const response = await GetWhitelist({ ...params });
    return response;
};

onMounted(async () => {
    whitelistList.value = await getWhitelist();
    isLoading.value = false;
});

const dateLimitString = (dateLimitObj) => {
    if (dateLimitObj.endDate === "infinity") {
        return "Beginning of time - End of Time";
    }
    return `${parseDateLong(dateLimitObj.startDate)} - ${parseDateLong(
        dateLimitObj.endDate
    )}`;
};

const createWhitelistModal = ref(false);

const statusBadge = (status) => {
    if (status === "Active") {
        return "success";
    } else if (status === "Scheduled") {
        return "primary";
    } else {
        return "";
    }
};

const whitelistName = ref("");
const usersChannel = ref("");
const startDate = ref("");
const endDate = ref("");
const isFormComplete = computed(() => {
    return (
        whitelistName.value != "" &&
        usersChannel.value != "" &&
        startDate.value != "" &&
        endDate.value != ""
    );
});

watch(createWhitelistModal, () => {
    whitelistName.value = "";
    usersChannel.value = "";
    startDate.value = "";
    endDate.value = "";
});

const toast = ref(false);
const el = ref();
const stopScrollLoad = ref(false);

useInfiniteScroll(
    el,
    async () => {
        if (
            isLoading.value ||
            stopScrollLoad.value ||
            whitelistList.value.length === 0
        ) {
            return;
        }
        const currentArr = [...whitelistList.value];
        const lastItem = currentArr.pop();
        const response = await getWhitelist({
            lastItem: lastItem.whitelistIdString,
        });
        if (response.length === 0) {
            stopScrollLoad.value = true;
            isLoading.value = false;
            return;
        }
        whitelistList.value = whitelistList.value.concat(response);
        isLoading.value = false;
    },
    { distance: 10 }
);
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 55vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
